/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { IconButton } from "@material-tailwind/react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'font-awesome/css/font-awesome.min.css'; 


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import Glide from "@glidejs/glide"
import Startupplandetail from "../Plansingledetails/Startupplandetail";
import Businessplandetail from "../Plansingledetails/Businessplandetail";
import Corporateplandetail from "../Plansingledetails/Corporateplandetail";
import Customplandetail from "../Plansingledetails/Customplandetail";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items:1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}
export default function Plansdetails() {
  return (
   <>
    
  <section className="mt-[5px]">
  <div class="mx-auto">
      <div  class="mb-[-10px] text-gray-600 body-font  ">
      <h3 class="text-center lg:text-[22px] font-bold sm:text-2xl"> Our Plans at Hickter     </h3>
        </div>
      </div>

      <Carousel responsive={responsive}  className="z-[0] overscroll-x-none lg:p-9 lg:mt-[-10px] p-6 mr-[-2px] ml-[-7px]" >


 {/* Startup Plan    */}
 <div className=" p-[3px] ml-[-10px] mt-[10px]">  
<Card  variant="gradient" className="p-[3px] bg-purple-700	
     mx-auto rounded ">
          <CardHeader color="blue-gray" className="auto w-cover h-15">
         <img
           src="./assets/plans/Starup Plan.webp"
         />
       </CardHeader>
          
          <CardBody className="mt-[-10px] ml-[-20px] " >
            
        <Typography
           variant="h1"
           color="white"
           className="mt-6 flex justify-center gap-1 text-7xl font-normal">
           <span className="mt-2 ml-2 text-[32px]">₹12,000  </span>{""}
           <span className="self-end text-[14px]">   / Yearly  </span> 
           <div className="text-center" class="flex gap-10">
  <div
     class="ml-[2px] relative grid items-center px-1 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-yellow-600">
     <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
       <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-yellow-300 content-['']"></span>
     </div> 
     <span class="ml-5"> <p class="mr-[9px] text-yellow-200 text-[22px] line-through ..."> ₹15,000 </p> </span>  
   </div>
 
 </div>  
         </Typography>
 
         <br></br>
         <Startupplandetail/>
          
      </CardBody>
      <CardFooter className=" mt-[-20px]" >
      <div className="gap-x-6 mt-[-15px] gap-y-6 grid-cols-2">  
       <tr className=" gap-x-2	" > 
          <td>
          <a href="/Startup_apply">  <Button className="bg-green-500 "> Apply Now <i style={{fontSize:13}} class="fa-solid fa-angles-right"></i></Button></a>
</td>


        </tr></div>
       
      </CardFooter>
      
      </Card></div>


{/* Business Plan    */}
      <div className=" p-[3px] ml-[-10px] mt-[10px]">  
<Card  variant="gradient" className="p-[3px] bg-purple-700	
     mx-auto rounded ">
          <CardHeader color="blue-gray" className="auto w-cover h-15">
         <img
           src="./assets/plans/Business Plan.webp"
         />
       </CardHeader>
          
          <CardBody className="mt-[-10px] ml-[-20px] " >
            
        <Typography
           variant="h1"
           color="white"
           className="mt-6 flex justify-center gap-1 text-7xl font-normal">
           <span className="mt-2 ml-2 text-[32px]">₹24,000  </span>{""}
           <span className="self-end text-[14px]">   / Yearly  </span> 
           <div className="text-center" class="flex gap-10">
  <div
     class="ml-[2px] relative grid items-center px-1 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-yellow-600">
     <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
       <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-yellow-300 content-['']"></span>
     </div> 
     <span class="ml-5"> <p class="mr-[9px] text-yellow-200 text-[22px] line-through ..."> ₹30,000 </p> </span>  
   </div>
 
 </div>  
         </Typography>
 
         <br></br>
         <Businessplandetail/>
          
      </CardBody>
      <CardFooter className=" mt-[-20px]" >
      <div className="gap-x-6 mt-[-15px] gap-y-6 grid-cols-2">  
       <tr className=" gap-x-2	" > 
          <td>
          <a href="/Businessform_apply">  <Button className="bg-green-500 "> Apply Now <i style={{fontSize:13}} class="fa-solid fa-angles-right"></i></Button></a>
</td>


        </tr></div>
       
      </CardFooter>
      
      </Card></div>


{/* Corporate Plan    */}

<div className=" p-[3px] ml-[-10px] mt-[10px]">  
<Card  variant="gradient" className="p-[3px] bg-purple-700	
     mx-auto rounded ">
          <CardHeader color="blue-gray" className="auto w-cover h-15">
         <img
           src="./assets/plans/Corporate Plan.webp"
         />
       </CardHeader>
          
          <CardBody className="mt-[-10px] ml-[-20px] " >
            
        <Typography
           variant="h1"
           color="white"
           className="mt-6 flex justify-center gap-1 text-7xl font-normal">
           <span className="mt-2 ml-2 text-[32px]">₹64,000  </span>{""}
           <span className="self-end text-[14px]">   / Yearly  </span> 
           <div className="text-center" class="flex gap-10">
  <div
     class="ml-[2px] relative grid items-center px-1 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-yellow-600">
     <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
       <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-yellow-300 content-['']"></span>
     </div> 
     <span class="ml-5"> <p class="mr-[9px] text-yellow-200 text-[22px] line-through ..."> ₹80,000 </p> </span>  
   </div>
 
 </div>  
         </Typography>
 
         <br></br>
         <Corporateplandetail/>
          
      </CardBody>
      <CardFooter className=" mt-[-20px]" >
      <div className="gap-x-6 mt-[-15px] gap-y-6 grid-cols-2">  
       <tr className=" gap-x-2	" > 
          <td>
          <a href="/Corporateform_apply">  <Button className="bg-green-500 "> Apply Now <i style={{fontSize:13}} class="fa-solid fa-angles-right"></i></Button></a>
</td> 
        </tr></div>
       
      </CardFooter>
      
      </Card></div>


{/* Custom Plan    */}

<div className=" p-[3px] ml-[-10px] mt-[10px]">  
<Card  variant="gradient" className="p-[3px] bg-purple-700	
     mx-auto rounded ">
          <CardHeader color="blue-gray" className="auto w-cover h-15">
         <img
           src="./assets/plans/Custom Plan.webp"
         />
       </CardHeader>
          
          <CardBody className="mt-[-10px] ml-[-20px] " >
            
        <Typography
           variant="h1"
           color="white"
           className="mt-6 flex justify-center gap-1 text-7xl font-normal">
           <span className="mt-2 ml-2 text-[32px]">₹1,20,000  </span>{""}
           <span className="self-end text-[14px]">   / Yearly  </span> 
           <div className="text-center" class="flex gap-10">
  <div
     class="ml-[2px] relative grid items-center px-1 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-yellow-600">
     <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
       <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-yellow-300 content-['']"></span>
     </div> 
     <span class="ml-5"> <p class="mr-[9px] text-yellow-200 text-[22px] line-through ..."> ₹1.5 Lac </p> </span>  
   </div>
 
 </div>  
         </Typography>
 
         <br></br>
         <Customplandetail/>
          
      </CardBody>
      <CardFooter className=" mt-[-20px]" >
      <div className="gap-x-6 mt-[-15px] gap-y-6 grid-cols-2">  
       <tr className=" gap-x-2	" > 
          <td>
          <a href="/Customform_apply">  <Button className="bg-green-500 "> Apply Now <i style={{fontSize:13}} class="fa-solid fa-angles-right"></i></Button></a>
</td>


        </tr></div>
       
      </CardFooter>
      
      </Card></div>


 
</Carousel>
 
  </section>

  
   
   </>
  )
}
 
