/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import Cardsk from '../Components/Cardsk'
import Skeletonui from '../Components/Skeletonui'
import { useState, useEffect } from "react";

import Navbarsection from "../views/Home/Navbarsection" 
  import Footersection from "../views/Home/Footersection"
  import Iframe from 'react-iframe'

export default function App_demo() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  
  return (
<>
 
<Navbarsection />

      <div className="">
 
      {loading ? <Skeletonui />  :  <Iframe url="./ss.html"
         width="100%"
         height="310px" 
         display="block"
         position=""/>   }

        {/*  Dextop Only  */}
        
 
       </div>

    
<Footersection />
 
  
  
</>
  )
}
 