 // eslint-disable-next-line no-unused-vars
import React from 'react'
import { 
    Typography, 
  } from "@material-tailwind/react";
import { Accordion } from 'flowbite-react';
function CheckIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="text-white h-3 w-3"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 12.75l6 6 9-13.5"
        />
      </svg>
    );
  }
 
export default function Corporateplandetail() {
  return (
   <> <Accordion className="p-[4px]" >
    <Accordion.Panel >
      <Accordion.Title className="  text-white  hover:text-black bg-red  font-bold" >Domain & Hosting? </Accordion.Title>
      <Accordion.Content>
        <p className="mb-2 text-white dark:text-gray-400">
        Free Domain ( .com/.org/.in etc ).<br></br>
Free Hosting ( Cloud Hosting ) <br></br> 
<ul><li>
100gb Storage
    </li> <li> Unlimited Bandwidth </li>  </ul>
        </p>
         
      </Accordion.Content>
    </Accordion.Panel>
    <Accordion.Panel>
      <Accordion.Title className="  text-white  hover:text-black bg-red  font-bold" > What you will Get in this plan? </Accordion.Title>
      <Accordion.Content>
        <p className="mb-2 text-gray-500 dark:text-gray-400">
        <table className="ml-[-10px] border-separate [border-spacing:0.75rem] w-full text-sm text-left rtl:text-right text-gray-500
        dark:text-gray-400"> 
             
             <tbody>
        {/*   1st  Section  */}
                   <tr className="bg-transparent  dark:bg-gray-800 dark:border-gray-700">
       
                   <td>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                          <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white/20 bg-green-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="text-white text-[14px] font-normal">App Development <div
    className="text-[10px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-1 font-sans  uppercase text-black">
    <span className="text-[9px]"> <i style={{fontSize:10}} className="text-green-500 fa-brands fa-android "></i>  <i style={{fontSize:10}} className="fa-brands text-blue-700 fa-app-store-ios ml-1 p-1"></i> Android & iOS  </span>
  </div>

 </Typography>

                  </li>
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                           <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white/20 bg-green-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
        </Typography>
                  </li>
                          </Typography>
                        </td>
       
                     </tr>
       
                    {/*  2nd section  */}
       
                     <tr> 
       
                     <td>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white/20 bg-green-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="text-white text-[14px]  font-normal">Digital Marketing 
        </Typography>
                  </li>
                          </Typography>
                        </td>
        
        
                        <td>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                          <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white/20 bg-green-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="text-white  text-[14px] font-normal">
                    Banner Ads    
                    </Typography>
                  </li>
                          </Typography>
                        </td>
       
                     </tr>
                     
       {/* 3rd section  */}
                     <tr>
                     <td>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                          <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white/20 bg-green-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="text-white text-[14px] font-normal">
                    Video Ads Creation
                    </Typography>
                  </li>
                          </Typography>
                        </td>
         
                        <td>
                          <Typography
                           variant="small"
                           color="blue-gray"
                           className="font-normal"
                          >
                       <li className="flex items-center gap-4">
                    <span className="rounded-full border border-white/20 bg-green-400 p-1">
                      <CheckIcon />
                    </span>
                    <Typography className="text-white text-[14px] font-normal ">
                    Upgrade Version+ 
                    </Typography>
                  </li>
                          </Typography>
                        </td>
       
                     </tr>
                      
                       {/* 4th section  */}
                       <tr>
        
        <td>
        <Typography
               variant="small"
               color="blue-gray"
               className="font-normal"
             >
             <li className="flex items-center gap-4">
       <span className="rounded-full border border-white/20 bg-green-400 p-1">
         <CheckIcon />
       </span>
       <Typography className="text-white text-[14px] font-normal ">
       Professional marketing
       </Typography>
     </li>
             </Typography>
           </td>


           <td>
             <Typography
               variant="small"
               color="blue-gray"
               className="font-normal"
             >
             <li className="flex items-center gap-4">
       <span className="rounded-full border border-white/20 bg-green-400 p-1">
         <CheckIcon />
       </span>
       <Typography className="text-white text-[14px] font-normal ">
       Full Stack Feature+ 
       </Typography>
     </li>
             </Typography>
           </td>
        </tr>
        
        {/* 5th Section */} 

        <tr >
  
           <td>
             <Typography
               variant="small"
               color="blue-gray"
               className="font-normal"
             >
             <li className="flex items-center gap-4">
       <span className="rounded-full border border-white/20 bg-green-400 p-1">
         <CheckIcon />
       </span>
       <Typography className="text-white text-[14px] font-normal">
       Company Formation+ 
       </Typography>
     </li>
             </Typography>
           </td>
        </tr>

                     </tbody>
                      
                     </table>
        </p>
      
      </Accordion.Content>
    </Accordion.Panel>
    <Accordion.Panel>

      <Accordion.Title className=" text-white  hover:text-black bg-red  font-bold" > Not Include in this plan? </Accordion.Title>
      <Accordion.Content>
        <table> <tbody> <tr> 
      <td>
             <Typography
               variant="small"
               color="blue-gray"
               className="font-normal"
             >
             <li className="flex items-center gap-4">
       <span className="rounded-full border border-white/20 bg-red-400 p-1">
         <CheckIcon />
       </span>
       <Typography className="text-white text-[14px] font-normal line-through">
       Advanced Customization +
       </Typography>
     </li>
             </Typography>
           </td>

 
        </tr>

                     </tbody>
                      
                     </table>
           
      </Accordion.Content>
    </Accordion.Panel>
 
  </Accordion>
  </>
  )
}
 

 
